import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name',
  standalone: true,
})
export class NamePipe implements PipeTransform {
  transform(username: string, name?: string, lastName?: string): string {
    if (name && lastName) {
      return `${name} ${lastName}`;
    }

    if (name) {
      return name;
    }

    if (lastName) {
      return lastName;
    }

    return username;
  }
}
